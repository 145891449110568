import axios from 'axios'

const state = () => ({
  message: '',
  data: [],
  selected: '',
})

const mutations = {
  setData (state, payload) {
    state.data = payload
  },
  postMessage (state, payload) {
    state.message = payload
  },
  selectedData (state, payload) {
    state.selected = payload
  },
}

const actions = {
  async getData ({ commit }) {
    await axios.get('/api/cropclass').then((res) => {
      commit('setData', res.data)
    })
  },

  async postData ({ dispatch }, crop) {
    await axios.post('/api/cropclass', crop).then(() => {
      dispatch('getData')
    })
  },

  async deleteData ({ dispatch }, cropid) {
    await axios
      .delete('/api/cropclass/' + cropid)
      .then(() => {
        dispatch('getData')
      })
  },

  async updateData ({ dispatch }, crop) {
    await axios
      .put('/api/cropclass/' + crop.id, crop)
      .then(() => {
        dispatch('getData')
      })
  },

  async selectedData ({ commit }, id) {
    commit('selectedData', id)
  },
}

const getters = {
  cropclassdata: (state) => {
    return state.data
  },

  cropclassbyId: (state) => (id) => {
    return state.data.find((dat) => dat.id === id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
