import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.config.productionTip = false

Vue.use( VueToast)
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://apiopa.agribiziloilo.com'

new Vue({
  router,
  store,
  vuetify,
  i18n,
  axios,
  render: h => h(App),
}).$mount('#app')

router.beforeEach((to,from,next)=>{
  
    if(to.matched.some(record=>record.meta.requiresAuth) ){
    if(store.state.authuser.authenticated===false){
        console.log("not authenticated")
      next({
        name:"Login"
      })
    }else{  
      next();
    }
  }else{
    next();
  }
});

