import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router= new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
          meta:{requiresAuth:true},
        },
        // Pages
        {
          name: 'Crops',
          path: 'crops',
          component: () => import('@/components/crops/List'),
          meta:{requiresAuth:true},
        },
        {
          name: 'Livestock',
          path: 'livestock',
          component: () => import('@/components/livestock/List'),
          meta:{requiresAuth:true},
        },
        {
          name: 'Poultry',
          path: 'poultry',
          component: () => import('@/components/poultry/List'),
          meta:{requiresAuth:true},
        },
        {
          name: 'Fishery',
          path: 'fishery',
          component: () => import('@/components/fishery/List'),
          meta:{requiresAuth:true},
        },
        {
          name: 'Municipal/ City',
          path: 'muncity',
          component: () => import('@/components/muncity/List'),
          meta:{requiresAuth:true},
        },
        {
          name: 'MunCityDetails',
          path: 'MunCityDetails',
          component: () => import('@/components/muncity/details'),
          meta:{requiresAuth:true},
        },
        {
          name: 'DataUploads',
          path: '/uploads',
          component: () => import('@/components/uploads'),
          meta:{requiresAuth:true},
        },
        
        // Login
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/components/auth/Login'),
        },
      ],
    },
    // { 
    //   name:'login',
    //   path: '/login',
    //   component: () => import('@/components/auth/Login'),
    // }
  ]
});

export default router;

