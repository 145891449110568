import cropclass from './store/modules/cropclass'
import cropdesc from './store/modules/cropdesc'
import cropvariety from './store/modules/cropvariety'
import livestockdesc from './store/modules/livestockdesc'
import livestockvariety from './store/modules/livestockvariety'
import poultrydesc from './store/modules/poutrydesc'
import poultryvariety from './store/modules/poultryvariety'
import fisherydesc from './store/modules/fisherydesc'
import fisheryvariety from './store/modules/fisheryvariety'
import muncity from './store/modules/muncity'
import mais from './store/modules/maisv2'
import authuser from './store/modules/authuser'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store= new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
  },
  actions: {

  },

  modules: {
    cropclass: cropclass,
    cropdesc: cropdesc,
    cropvariety: cropvariety,
    livestockdesc: livestockdesc,
    livestockvariety: livestockvariety,
    poultrydesc: poultrydesc,
    poultryvariety: poultryvariety,
    fisherydesc: fisherydesc,
    fisheryvariety: fisheryvariety,
    muncity:muncity,
    mais:mais,
    authuser:authuser,
  },
})


export default store