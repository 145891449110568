import axios from 'axios'

const state = () => ({
  message: '',
  data: [],
  selected: '',
})

const mutations = {
  setData (state, payload) {
    state.data = payload
  },
  postMessage (state, payload) {
    state.message = payload
  },
  selectedData (state, payload) {
    state.selected = payload
  },
}

const actions = {
  async getData ({ commit }, animaldescid) {
    await axios
      .get('/api/animalvariety/' + animaldescid)
      .then((res) => {
        commit('setData', res.data)
      })
  },

  async postData ({ dispatch }, animalvariety) {
    await axios
      .post('/api/animalvariety', animalvariety)
      .then(() => {
        dispatch('getData', animalvariety.animalDesc_id)
      })
  },

  async deleteData ({ dispatch }, animalvariety) {
    await axios
      .delete('/api/animalvariety/' + animalvariety.id)
      .then(() => {
        dispatch('getData', animalvariety.animalDesc_id)
      })
  },

  async updateData ({ dispatch }, animalvariety) {
    await axios
      .put(
        '/api/animalvariety/' + animalvariety.id,
        animalvariety,
      )
      .then(() => {
        dispatch('getData', animalvariety.animaldesc_id)
      })
  },

  async selectedData ({ commit }, id) {
    commit('selectedData', id)
  },
}

const getters = {
  fisheryvarietydata: (state) => {
    return state.data
  },

  fisherybyId: (state) => (id) => {
    return state.data.find((dat) => dat.id === id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
