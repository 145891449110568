import axios from 'axios'

const state = () => ({
  message: '',
  data: [],
  cropclassid: '',
  selected: '',
})

const mutations = {
  setData (state, payload) {
    state.data = payload
  },
  postMessage (state, payload) {
    state.message = payload
  },
  setCropClassid (state, payload) {
    state.cropclassid = payload
  },
  setSelected (state, payload) {
    state.selected = payload
  },
}

const actions = {
  async getData ({ commit }, cropclassid) {
    await axios
      .get('/api/cropdesc/' + cropclassid)
      .then((res) => {
        commit('setData', res.data)
      })
  },

  async postData ({ state, dispatch }, crop) {
    crop.cropclass_id = state.cropclassid
    await axios.post('/api/cropdesc', crop).then(() => {
      dispatch('getData')
    })
  },

  async deleteData ({ dispatch }, cropid) {
    await axios
      .delete('/api/cropdesc/' + cropid)
      .then(() => {
        dispatch('getData')
      })
  },

  async updateData ({ state, dispatch }, crop) {
    crop.cropclass_id = state.cropclassid
    await axios
      .put('/api/cropdesc/' + crop.id, crop)
      .then(() => {
        dispatch('getData')
      })
  },

  async setCropClassid ({ commit }, payload) {
    commit('setCropClassid', payload)
  },
  async setSelected ({ commit }, payload) {
    commit('setSelected', payload)
  },
}

const getters = {
  cropdescdata: (state) => {
    return state.data
  },

  cropclassid: (state) => {
    return state.cropclassid
  },

  cropdescbyId: (state) => (id) => {
    return state.data.find((dat) => dat.id === id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
