import axios from "axios";

const state = () => ({
  data: [],
  selected: '',
  alluploads:[]
});

const mutations = {
  setData(state, payload) {
    state.data = payload;
  },

  setAlluploads(state,payload){
    state.alluploads=payload;
  },
  setSel(state,payload){
    state.selected=payload
  }
};

const actions = {
  async getData({ commit }) {
    await axios.get("/api/munCity").then(res => {
      commit("setData", res.data);
    });
  },
  async getAlluploads({ commit }) {
    await axios.get("/api/uploads").then(res => {
      commit("setAlluploads", res.data);
    });
  },

  setSelected({commit},payload){
    commit('setSel',payload)
  }
};

const getters = {
  munCityData: state => {
    return state.data;
  },

  munCityName:state=>{
    return state.selected.munCityName.toLowerCase();
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
