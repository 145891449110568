import axios from "axios";

const state = () => ({
  uploadlogs: [],
  crops:[],
  livestock:[],
  poultry:[],
});

const mutations = {
  setUploadlogs(state, payload) {
    state.uploadlogs = payload;
  },
  setCrops(state,payload){
      state.crops=payload;
  },
  setLivestock(state,payload){
    state.livestock=payload;
  },
  setPoultry(state,payload){
    state.poultry=payload;
  }
};

const actions = {
  async getUploadlog({ commit },muncity) {
    await axios.get("/api/mais/"+muncity).then(res => {
      commit("setUploadlogs", res.data);
    });
  },

  async getCrops({commit},muncity){
      await axios.get('/api/mais/crops/'+muncity).then(res=>{
          commit('setCrops',res.data)
      })
  },

  async getLivestock({commit},muncity){
    await axios.get('/api/mais/livestock/'+muncity).then(res=>{
        commit('setLivestock',res.data)
    })
   },
   async getPoultry({commit},muncity){
    await axios.get('/api/mais/poultry/'+muncity).then(res=>{
        commit('setPoultry',res.data)
    })
   }
};

const getters = {
  uploadLogsData: state => {
    return state.uploadlogs;
  },

  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
