import axios from 'axios'

const state = () => ({
  message: '',
  data: [],
  cropdescid: '',
  selected: '',
})

const mutations = {
  setData (state, payload) {
    state.data = payload
  },
  postMessage (state, payload) {
    state.message = payload
  },
  setCropDescid (state, payload) {
    state.cropdescid = payload
  },
  setSelected (state, payload) {
    state.selected = payload
  },
}

const actions = {
  async getData ({ commit }, cropdescid) {
    await commit('setCropDescid', cropdescid)
    await axios
      .get('/api/cropvariety/' + cropdescid)
      .then((res) => {
        commit('setData', res.data)
      })
  },

  async postData ({ state, dispatch }, crop) {
    crop.cropdesc_id = state.cropdescid

    await axios.post('/api/cropvariety', crop).then(() => {
      dispatch('getData', crop.cropdescid)
    })
  },

  async deleteData ({ dispatch, state }, cropid) {
    await axios
      .delete('/api/cropvariety/' + cropid)
      .then(() => {
        dispatch('getData', state.cropdescid)
      })
  },

  async updateData ({ state, dispatch }, crop) {
    crop.cropdesc_id = state.cropdescid
    await axios
      .put('/api/cropvariety/' + crop.id, crop)
      .then(() => {
        dispatch('getData', crop.cropdesc_id)
      })
  },

  async setCropDescID ({ commit }, payload) {
    commit('setCropDescid', payload)
  },
  async setSelected ({ commit }, payload) {
    commit('setSelected', payload)
  },
}

const getters = {
  cropvarietydata: (state) => {
    return state.data
  },

  cropdescid: (state) => {
    return state.cropdescid
  },

  cropvarietybyId: (state) => (id) => {
    return state.data.find((dat) => dat.id === id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
