import axios from 'axios'

const state = () => ({
  message: '',
  data: [],
  selected: '',
})

const mutations = {
  setData (state, payload) {
    state.data = payload
  },
  postMessage (state, payload) {
    state.message = payload
  },
  selectedData (state, payload) {
    state.selected = payload
  },
}

const actions = {
  async getData ({ commit }) {
    await axios.get('/api/animaldesc/4').then((res) => {
      commit('setData', res.data)
    })
  },

  async postData ({ dispatch }, fishery) {
    await axios
      .post('/api/animaldesc', fishery)
      .then(() => {
        dispatch('getData')
      })
  },

  async deleteData ({ dispatch }, fisheryid) {
    await axios
      .delete('/api/animaldesc/' + fisheryid)
      .then(() => {
        dispatch('getData')
      })
  },

  async updateData ({ dispatch }, fishery) {
    await axios
      .put('/api/animaldesc/' + fishery.id, fishery)
      .then(() => {
        dispatch('getData')
      })
  },

  async selectedData ({ commit }, id) {
    commit('selectedData', id)
  },
}

const getters = {
  fisherydata: (state) => {
    return state.data
  },

  fisherybyId: (state) => (id) => {
    return state.data.find((dat) => dat.id === id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
