import axios from 'axios'

const state = () => ({
  authdata: '',
  authenticated:false,
})

const mutations = {
  setData (state, payload) {
    state.authdata = payload
  },
  async setAuth(state,payload){
    state.authenticated=payload
  }
}

const actions = {
  async login ({ commit },payload) {  
     await axios.get('/sanctum/csrf-cookie').then(async () => {
       await axios.post('/login', payload).then((res) => {
         
        if(res.status=='200'){
          commit('setAuth',true)
        }else{
          commit('setAuth',false)
        }
      })
    })
  },

    async logout({commit}){
      commit('setAuth',false)
      // await axios.post('/logout').then(()=>{
      //   commit('setAuth',false)
      // })
      
    }
}

const getters = {
  getEmail: (state) => {
    return state.authdata.email
  },
  getName: (state) => {
    return state.authdata.name
  },
  getAuth:(state)=>{
    return state.authenticated
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
